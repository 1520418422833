import { type M3ColorTokens, type M3ThemeTones } from "./M3Theme";

// https://m3.material.io/theme-builder#/custom
// core primary: `#90caf9`

export const lightColorTokens: M3ColorTokens = {
  primary: "#006496",
  onPrimary: "#ffffff",
  primaryContainer: "#c9e6ff",
  onPrimaryContainer: "#001e31",
  secondary: "#50606e",
  onSecondary: "#ffffff",
  secondaryContainer: "#d3e4f5",
  onSecondaryContainer: "#0d1d29",
  tertiary: "#65587b",
  onTertiary: "#ffffff",
  tertiaryContainer: "#eddcff",
  onTertiaryContainer: "#211634",
  error: "#ba1b1b",
  onError: "#ffffff",
  errorContainer: "#ffdad4",
  onErrorContainer: "#410001",
  background: "#fcfcff",
  onBackground: "#1a1c1e",
  surface: "#fcfcff",
  onSurface: "#1a1c1e",
  surfaceVariant: "#dee3ea",
  onSurfaceVariant: "#41474d",
  outline: "#72787e",
  shadow: "#000000",
  inverseSurface: "#2f3032",
  inverseOnSurface: "#f0f0f3",
  inversePrimary: "#8bcdff",
};

export const darkColorTokens: M3ColorTokens = {
  primary: "#8bcdff",
  onPrimary: "#003350",
  primaryContainer: "#004b72",
  onPrimaryContainer: "#c9e6ff",
  secondary: "#b7c8d9",
  onSecondary: "#22323f",
  secondaryContainer: "#394956",
  onSecondaryContainer: "#d3e4f5",
  tertiary: "#d0bfe8",
  onTertiary: "#362b4a",
  tertiaryContainer: "#4d4162",
  onTertiaryContainer: "#eddcff",
  error: "#ffb4a9",
  onError: "#680003",
  errorContainer: "#930006",
  onErrorContainer: "#ffb4a9",
  background: "#1a1c1e",
  onBackground: "#e2e2e5",
  surface: "#1a1c1e",
  onSurface: "#e2e2e5",
  surfaceVariant: "#41474d",
  onSurfaceVariant: "#c2c7ce",
  outline: "#8b9198",
  shadow: "#000000",
  inverseSurface: "#e2e2e5",
  inverseOnSurface: "#2f3032",
  inversePrimary: "#006496",
};

export const tones: M3ThemeTones = {
  primary: {
    "0": "#000000",
    "10": "#001e31",
    "20": "#003350",
    "30": "#004b72",
    "40": "#006496",
    "50": "#0d7eb8",
    "60": "#3c98d4",
    "70": "#5cb3f0",
    "80": "#8bcdff",
    "90": "#c9e6ff",
    "95": "#e5f2ff",
    "99": "#fbfcff",
    "100": "#ffffff",
  },
  secondary: {
    "0": "#000000",
    "10": "#0d1d29",
    "20": "#22323f",
    "30": "#394956",
    "40": "#50606e",
    "50": "#697988",
    "60": "#8293a3",
    "70": "#9dadbd",
    "80": "#b7c8d9",
    "90": "#d3e4f5",
    "95": "#e5f2ff",
    "99": "#fbfcff",
    "100": "#ffffff",
  },
  tertiary: {
    "0": "#000000",
    "10": "#211634",
    "20": "#362b4a",
    "30": "#4d4162",
    "40": "#65587b",
    "50": "#7f7195",
    "60": "#998ab0",
    "70": "#b4a5cb",
    "80": "#d0bfe8",
    "90": "#eddcff",
    "95": "#f8edff",
    "99": "#fffbfc",
    "100": "#ffffff",
  },
  neutral: {
    "0": "#000000",
    "10": "#1a1c1e",
    "20": "#2f3032",
    "30": "#45474a",
    "40": "#5d5e61",
    "50": "#75777a",
    "60": "#909194",
    "70": "#aaabae",
    "80": "#c5c6c9",
    "90": "#e2e2e5",
    "95": "#f0f0f3",
    "99": "#fcfcff",
    "100": "#ffffff",
  },
  neutralVariant: {
    "0": "#000000",
    "10": "#161c22",
    "20": "#2b3137",
    "30": "#41474d",
    "40": "#595f65",
    "50": "#72787e",
    "60": "#8b9198",
    "70": "#a6acb3",
    "80": "#c2c7ce",
    "90": "#dee3ea",
    "95": "#ecf1f9",
    "99": "#fbfcff",
    "100": "#ffffff",
  },
  error: {
    "0": "#000000",
    "10": "#410001",
    "20": "#680003",
    "30": "#930006",
    "40": "#ba1b1b",
    "50": "#dd3730",
    "60": "#ff5449",
    "70": "#ff897a",
    "80": "#ffb4a9",
    "90": "#ffdad4",
    "95": "#ffede9",
    "99": "#fcfcfc",
    "100": "#ffffff",
  },
};
