exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-outputs-tsx": () => import("./../../../src/pages/outputs.tsx" /* webpackChunkName: "component---src-pages-outputs-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-articles-backlog-notify-v-4-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/home/runner/work/portfolio/portfolio/content/articles/backlog-notify-v4.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-articles-backlog-notify-v-4-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-articles-github-backlog-notify-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/home/runner/work/portfolio/portfolio/content/articles/github-backlog-notify.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-articles-github-backlog-notify-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-articles-privacy-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/home/runner/work/portfolio/portfolio/content/articles/privacy.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-articles-privacy-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-articles-react-admin-japanese-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/home/runner/work/portfolio/portfolio/content/articles/react-admin-japanese.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-articles-react-admin-japanese-mdx" */)
}

